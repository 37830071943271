import { ChainId } from '@pancakeswap/sdk'
import { useActiveIfoWithBlocks } from 'hooks/useActiveIfoWithBlocks'
import { useUserCakeLockStatus } from 'hooks/useUserCakeLockStatus'
import { useMemo } from 'react'
import { useChainCurrentBlock } from 'state/block/hooks'
import { PotteryDepositStatus } from 'state/types'
import { getStatus } from 'views/Ifos/hooks/helpers'
import { useCompetitionStatus } from './useCompetitionStatus'
// import { usePotteryStatus } from './usePotteryStatus'
import { useVotingStatus } from './useVotingStatus'
import { useTradingRewardStatus } from './useTradingRewardStatus'

export const useMenuItemsStatus = () => {
  // const currentBlock = useChainCurrentBlock(ChainId.BSC)
  // const activeIfo = useActiveIfoWithBlocks()
  // const competitionStatus = useCompetitionStatus()
  // const potteryStatus = usePotteryStatus()
  // const votingStatus = useVotingStatus()
  // const isUserLocked = useUserCakeLockStatus()
  // const tradingRewardStatus = useTradingRewardStatus()

  // const ifoStatus =
  //   currentBlock && activeIfo && activeIfo.endBlock > currentBlock
  //     ? getStatus(Number(currentBlock), activeIfo.startBlock, activeIfo.endBlock)
  //     : null

  return {}
  // return useMemo(() => {
  //   return {
  //     '/competition': competitionStatus,
  //     // '/ifo': ifoStatus === 'coming_soon' ? 'soon' : ifoStatus,
  //     // ...(potteryStatus === PotteryDepositStatus.BEFORE_LOCK && {
  //     //   '/pottery': 'pot_open',
  //     // }),
  //     // ...(votingStatus && {
  //     //   '/voting': votingStatus,
  //     // }),
  //     // ...(isUserLocked && {
  //     //   '/pools': 'lock_end',
  //     // }),
  //     // ...(tradingRewardStatus && {
  //     //   '/trading-reward': tradingRewardStatus,
  //     // }),
  //   }
  // }, [competitionStatus, ifoStatus, potteryStatus, votingStatus, isUserLocked, tradingRewardStatus])
}
