import { Box, Flex, LinkExternal } from '@pancakeswap/uikit'
import { FC, useEffect, useState } from 'react'
import { useWeb3React } from '@pancakeswap/wagmi'
import RichTextEditor from 'components/RichText'
import {
  useGetDecryptedContent,
  useGetPaywallARP,
  useGetSubscriptionStatus,
  useGetThumbnailNContent,
} from 'state/cancan/hooks'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import Iframe from 'react-iframe'
import { useRouter } from 'next/router'

import { RoundedImage } from '../Collection/IndividualNFTPage/shared/styles'
import { cancanBaseUrl } from '../constants'

const StyledAspectRatio = styled(Box)`
  position: absolute;
  inset: 0;
`

export const AspectRatio = ({ ratio, children, ...props }) => (
  <Box width="100%" height={0} pb={`${100 / ratio}%`} position="relative" {...props}>
    <StyledAspectRatio>{children}</StyledAspectRatio>
  </Box>
)

export const decryptArticle = (
  str,
  key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCiNm2sI2/dOMJ0bYg5Roi6iiQeuVIwdiC1cbwOABtBOTMQDfUTjwyhLhUWSZ9AISYafryLKn6AFYZDZT+d52yIg7+BNlDzA5T43d4WMCfo0j/guZuD7dqTilP2pZV9lGzdLWYAKH0aDBoP/awCcvOk4gvQvwC89eMUNYJ9AaSelQIDAQAB',
) => {
  try {
    console.log('decryptArticle===================>')
    const crypto = require('crypto')
    // encryption algorithm
    const algorithm = 'aes-256-cbc'
    // create a decipher object
    const decipher = crypto.createDecipher(algorithm, key)
    // decrypt the encrypted text
    let decrypted = decipher.update(str, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    console.log('decryptArticle===================>', str, decrypted)
    return decrypted
  } catch (err) {
    console.log('1decryptArticle===================>', err, str)
  }
  return undefined
}

export const encryptArticle = (
  str,
  key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCiNm2sI2/dOMJ0bYg5Roi6iiQeuVIwdiC1cbwOABtBOTMQDfUTjwyhLhUWSZ9AISYafryLKn6AFYZDZT+d52yIg7+BNlDzA5T43d4WMCfo0j/guZuD7dqTilP2pZV9lGzdLWYAKH0aDBoP/awCcvOk4gvQvwC89eMUNYJ9AaSelQIDAQAB',
) => {
  const crypto = require('crypto')
  // encryption algorithm
  const algorithm = 'aes-256-cbc'
  // create a cipher object
  const cipher = crypto.createCipher(algorithm, key)
  // encrypt the plain text
  let encrypted = cipher.update(str, 'utf8', 'hex')
  encrypted += cipher.final('hex')
  return encrypted
}

const NFTMedia: FC<any> = ({
  width,
  height,
  nft,
  tokenURI = null,
  media = null,
  showThumbnail = true,
  as,
  ...props
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const _article = ''
  const { mp4, thumbnail, isArticle, contentType } = useGetThumbnailNContent(nft)
  const paywallId = useRouter().query.paywallId as any
  const [article, setArticle] = useState(_article ?? '')
  const paywallARP = useGetPaywallARP(nft?.collection?.id ?? '', paywallId ?? '') as any
  const { ongoingSubscription } = useGetSubscriptionStatus(
    paywallARP?.paywallAddress ?? '',
    account ?? '',
    '0',
    paywallId,
  )

  let _thumbnail = thumbnail
  let _mp4 = article
  const { mp4: __mp4, thumbnail: __thumbnail, refetch } = useGetDecryptedContent(thumbnail, mp4)
  console.log('nftmedia==============+>', nft)

  useEffect(() => {
    refetch()
  }, [mp4, refetch])

  if (!showThumbnail) {
    // if (!isArticle) {
    _thumbnail = __thumbnail
    _mp4 = __mp4
    // }
    if (tokenURI?.metadataUrl) {
      return <Iframe url={tokenURI.metadataUrl} height="500px" id="myId" />
      // eslint-disable-next-line no-else-return
    } else if (media) {
      return (
        <RichTextEditor
          readOnly
          value={`<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${
            media ?? mp4
          }" height="467" width="830"></iframe>`}
          id="rte"
        />
      )
    } else if (contentType === 'video') {
      return <RichTextEditor readOnly value={mp4} id="rte" />
    } else if (isArticle) {
      if (!parseInt(nft?.behindPaywall)) {
        return <RichTextEditor value={mp4} readOnly id="rte" />
      }
      if (ongoingSubscription) {
        return (
          <Flex flexDirection="column">
            <RichTextEditor value={__mp4} readOnly id="rte" />
          </Flex>
        )
      }
      return (
        <Flex flexDirection="column">
          <RichTextEditor value={__mp4?.slice(0, 2000)} readOnly id="rte" />
          <LinkExternal mt="8px" href={`${cancanBaseUrl}/collections/${nft?.collection?.id}/paywall/${paywallId}`} bold>
            {t('Subscribe to read more')}
          </LinkExternal>
        </Flex>
      )
    }
    // eslint-disable-next-line no-param-reassign
    media = ongoingSubscription ? __mp4 : thumbnail
  }
  return <RoundedImage width={width} height={height} src={media ?? _thumbnail} alt={nft?.tokenId} as={as} {...props} />
}

export default NFTMedia
