import NodeRSA from 'encrypt-rsa'

import { decryptArticle } from 'views/CanCan/market/components/NFTMedia'

export function getThumbnailNContent(nft) {
  const chunks = nft?.images && nft?.images?.split(',')
  try {
    if (chunks?.length && chunks[0] === 'img') {
      return {
        mp4: chunks[2],
        thumbnail: chunks[1],
        isArticle: false,
        contentType: chunks[0],
      }
    }
    if (chunks?.length && chunks[0] === 'video') {
      return {
        mp4: chunks[2],
        thumbnail: chunks[1],
        isArticle: false,
        contentType: chunks[0],
      }
    }
    if (chunks?.length && chunks[0] === 'form') {
      return {
        mp4: chunks[2],
        thumbnail: chunks[1],
        isArticle: false,
        contentType: chunks[0],
      }
    }
    if (chunks?.length && chunks[0] === 'article') {
      return {
        mp4: chunks?.slice(2).join(','),
        thumbnail: chunks[1],
        isArticle: true,
        contentType: chunks[0],
      }
    }
  } catch (err) {
    console.log('1getThumbnailNContent===============>', err, nft)
  }
  return {
    mp4: chunks?.length && chunks[0],
    thumbnail: chunks?.length && chunks[0],
    isArticle: false,
    contentType: chunks?.length ? 'paywall' : '',
  }
}

export function decryptContent(thumbnail, mp4) {
  console.log('decryptContent===================>')
  // const crypto = require('crypto')
  // // encryption algorithm
  // const algorithm = 'aes-256-cbc'
  // // create a decipher object
  // const decipher = crypto.createDecipher(algorithm, process.env.NEXT_PUBLIC_PUBLIC_KEY_4096)
  // // decrypt the encrypted text
  // let decrypted = decipher.update(mp4, 'hex', 'utf8')
  // decrypted += decipher.final('utf8')
  // console.log('decryptContent===================>', mp4, decrypted)
  return {
    mp4: decryptArticle(mp4),
    thumbnail,
  }
}

export function decryptItem(thumbnail, mp4) {
  console.log('decryptItem===================>')
  const nodeRSA = new NodeRSA(process.env.NEXT_PUBLIC_PUBLIC_KEY, process.env.NEXT_PUBLIC_PRIVATE_KEY)
  const dcrpt = mp4
    ? nodeRSA.decryptStringWithRsaPrivateKey({
        text: mp4,
        privateKey: process.env.NEXT_PUBLIC_PRIVATE_KEY_4096,
      })
    : ''
  return {
    mp4: dcrpt,
    thumbnail,
  }
}

// function chunkString(str, length) {
//   return str.match(new RegExp(`.{1,${length}}`, 'g'))
// }

export const encryptCall = (encryptRsa, call) => {
  return encryptRsa.encryptStringWithRsaPublicKey({
    text: call,
    publicKey: process.env.NEXT_PUBLIC_PUBLIC_KEY,
  })
}

// export const decryptArticle = (str) => {
//   try {
//     console.log('decryptArticle===================>')
//     const crypto = require('crypto')
//     // encryption algorithm
//     const algorithm = 'aes-256-cbc'
//     // create a decipher object
//     const decipher = crypto.createDecipher(algorithm, process.env.NEXT_PUBLIC_PUBLIC_KEY_4096)
//     // decrypt the encrypted text
//     let decrypted = decipher.update(str, 'hex', 'utf8')
//     decrypted += decipher.final('utf8')
//     console.log('decryptArticle===================>', str, decrypted)
//     return decrypted
//   } catch (err) {
//     console.log('1decryptArticle===================>', err, str)
//   }
// }

// export const encryptArticle = (str) => {
//   const crypto = require('crypto')
//   // encryption algorithm
//   const algorithm = 'aes-256-cbc'
//   // create a cipher object
//   const cipher = crypto.createCipher(algorithm, process.env.NEXT_PUBLIC_PUBLIC_KEY_4096)
//   // encrypt the plain text
//   let encrypted = cipher.update(str, 'utf8', 'hex')
//   encrypted += cipher.final('hex')
//   return encrypted
// }
