export const futureCollateralFields = `
  id
  owner
  active
  channel
  bountyId
  endebted
  auditorBountyId
  auditor
  metadataUrl
  createdAt
`
